@charset "utf-8"; ///////////////////////////////////////////
// font.scss
///////////////////////////////////////////
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900");
@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 400;
  src: url("//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.woff2") format("woff2"), url("//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.woff") format("woff"), url("//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.otf") format("opentype");
}
@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 500;
  src: url("//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Medium.woff2") format("woff2"), url("//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Medium.woff") format("woff"), url("//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Medium.otf") format("opentype");
}
@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 700;
  src: url("//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.woff2") format("woff2"), url("//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.woff") format("woff"), url("//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.otf") format("opentype");
}
$font-jp: "Lato", "Noto Sans JP", 游ゴシック体, "Yu Gothic", YuGothic, "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
$font-en: "Lato", sans-serif;

body {
  font-family: $font-jp;
}

.bold,
.fw-700,
strong {
  font-weight: 700;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.italic {
  font-style: italic;
}
// dt,
// h1,
// h2,
// h3,
// h4,
// h5,
// h6,
// strong,
// th {
//   font-weight: 500;
// }

.fs-10 {
  font-size: 10px;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.fs-24 {
  font-size: 24px;
  line-height: inherit;
}

.fs-32 {
  font-size: 32px;
}

.fs-sp-20 {
  @include fsvw(20);
}

.fs-sp-21 {
  @include fsvw(21);
}

.fs-sp-22 {
  @include fsvw(22);
}

.fs-sp-24 {
  @include fsvw(24);
}

.fs-sp-27 {
  @include fsvw(27);
}

.fs-sp-32 {
  @include fsvw(32);
}

.fs-sp-36 {
  @include fsvw(36);
}

.fs-sp-40 {
  @include fsvw(40);
}

.fs-sp-48 {
  @include fsvw(48);
}

.fontsize-s {
  @include fontsize(10, 20);
}

.text-s {
  @include font(10, 18, 80, 20, 20, 80);
}

.fontsize-m {
  @include fontsize(12, 24);
}

.ffs-p {
  font-feature-settings: "palt";
}