@charset "utf-8"; ///////////////////////////////////////////
// mixin.scss
///////////////////////////////////////////
@mixin font($fts:10,$lin:10,$ls:0,$fts2:0,$lin2:0,$ls2:0) {
  $rem: $fts / 10;
  $linS: $lin / $fts;
  $ls: $ls / 1000;
  font-size: #{$fts}px;
  font-size: #{$rem}rem;
  @if $lin != $fts {
    line-height: $linS;
  }
  @if $ls != 0 {
    letter-spacing: #{$ls}em;
  }
  @include media(sp) {
    $rem2: $fts2 / 10;
    $linS2: $lin2 / $fts2;
    $vw2: $fts2 / $sp-size * 100;
    $ls2: $ls2 / 1000;
    font-size: #{$fts2}px;
    font-size: #{$rem2}rem;
    font-size: #{$vw2}vw;
    @if $fts2 != $lin2 {
      line-height: $linS2;
    }
    @if $ls != $ls2 {
      letter-spacing: #{$ls2}em;
    }
  }
}
@mixin fontsize($fts:10,$fts2:20) {
  $rem: $fts / 10;
  font-size: #{$fts}px;
  font-size: #{$rem}rem;
  @include media(sp) {
    $rem2: $fts2 / 10;
    $vw2: $fts2 / $sp-size * 100;
    font-size: #{$rem2}rem;
    font-size: #{$vw2}vw;
  }
}
@mixin fsvw($fs:20) {
  @include media(sp) {
    $vw: $fs / $sp-size * 100;
    font-size: #{$vw}vw;
  }
}
@mixin lineheight($fts:10,$lin:10,$fts2:0,$lin2:0) {
  $linS: $lin / $fts;
  @if $lin != $fts {
    line-height: $linS;
  }
  @include media(sp) {
    $linS2: $lin2 / $fts2;
    @if $linS != $linS2 {
      line-height: $linS2;
    }
  }
}
@mixin ls($ls:0) {
  $ls: $ls / 1000;
  @if $ls != 0 {
    letter-spacing: #{$ls}em;
  }
}
@mixin parent($selector) {
  @at-root #{$selector} & {
    @content;
  }
}
// メディアクエリ
@mixin media($media-width) {
  @if $media-width == sp {
    @media only screen and (max-width:$bp-sp) {
      @content;
    }
  } @else 
  if $media-width == pctb {
    @media only screen and (min-width:$bp-pctb) {
      @content;
    }
  } @else 
  if $media-width == tb {
    @media only screen and (max-width:$bp-tb) {
      @content;
    }
  } @else 
  if $media-width == pc {
    @media only screen and (min-width: $bp-pc) {
      @content;
    }
  }
}
// メディアクエリ Height
@mixin mediaH($media-heightH) {
  @if $media-heightH == supernarrow {
    @media only screen and (max-height: $bp-supernarrow) {
      @content;
    }
  } @else 
  if $media-heightH == narrow {
    @media only screen and (max-height:$bp-narrow) {
      @content;
    }
  }
}
// トランジション
$easeInExpo: cubic-bezier(0.950, 0.050, 0.795, 0.035);
$easeInCirc: cubic-bezier(0.600, 0.040, 0.980, 0.335);
$easeOutCirc: cubic-bezier(0.075, 0.820, 0.165, 1.000);
$easeOutExpo: cubic-bezier(0.190, 1.000, 0.220, 1.000);
$easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1.000);
$easeOutCubic: cubic-bezier(0.215, 0.610, 0.355, 1.000);
$easeCustom: cubic-bezier(0.065, 0.630, 0.065, 1.255);
@mixin trans($selector:all,$time:1s,$delay:0s ,$timing:ease) {
  transition-property: $selector;
  transition-duration: $time;
  transition-delay: $delay;
  transition-timing-function: $timing;
  // ease
  // linear
  // ease-in
  // ease-out
  // ease-in-out
  // cubic-bezier(数値, 数値, 数値, 数値)
}
// sample...
// @include trans(opacity, 0.3s, 0s,$easeInOutCubic);
@mixin vw($selector,$num1:'',$num2:'',$num3:'',$num4:'') {
  // $num-px : $num1 * 0.5px;
  $num-vw: 100vw * $num1/$sp-size;

  @if $num2 != '' {
    // $num-px : $num-px +' '+ ($num2 * 0.5px);
    $num-vw: $num-vw +' '+ (100vw * $num2/$sp-size);
  }

  @if $num3 != '' {
    // $num-px : $num-px +' '+ ($num3 * 0.5px);
    $num-vw: $num-vw +' '+ (100vw * $num3/$sp-size);
  }

  @if $num4 != '' {
    // $num-px : $num-px +' '+ ($num4 * 0.5px);
    $num-vw: $num-vw +' '+ (100vw * $num4/$sp-size);
  }
  // #{$selector} : #{$num-px};
  #{$selector}: #{$num-vw};
}