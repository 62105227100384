@charset "utf-8"; ///////////////////////////////////////////
// generic.scss
///////////////////////////////////////////

html {
  font-size: 62.5%;
}

body {
  background: $body-background;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  // min-width: 1080px;
  letter-spacing: 0.08em;
  color: $color-default;
  @include media(sp) {
    min-width: 320px;
  }
}

input {
  font-family: $font-jp;
}

a {
  cursor: pointer;
  color: $color-default;
  text-decoration: none;
}

a.link-sp {
  pointer-events: none;
  @include media(sp) {
    pointer-events: auto;
  }
}

img {
  width: 100%;
  height: auto;
}

.underline {
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.anchor {
  position: relative;
  top: -72px;
  left: 0;
  @include media(sp) {
    top: 0;
  }
}
