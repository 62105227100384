@charset "utf-8"; // $selector: opacity transform;
// @include trans($selector, 0.4s, 0s,$easeInOutCubic);
// @include media(tb){
// 	padding-top: 220px * 0.75;
// }
// @include font(24,28,50,22,18);
// font-feature-settings: "palt";
//@include media(sp){
// }
// @include media(sp){}
// Common

body,
html {
  height: 100%;
  // min-width: 1080px;
  @include media(sp) {
    min-width: 320px;
  }
}

html {}

body {
  img {
    width: 100%;
    height: auto;
  }
}

#app {
  overflow: hidden;
}

.wrapper {
  background-color: $body-background;
  z-index: 1;
  position: relative;
  padding-bottom: 1px;
  @include trans(margin-left, 0.5s, 0s,$easeInOutCubic);
  min-height: 100vh;
  @include media(sp) {
    @include trans(transform, 0.5s, 0s,$easeInOutCubic);
  }

  &.show {
    margin-left: 300px;
    // transform: translateX(300px);
    @include media(sp) {
      margin-left: 0;
      @include vw(min-width,590);
      transform: translateX(100%);
    }
  }
}

.box {
  position: relative;
  z-index: 1;
  width: 92%;
  max-width: 1160px;
  margin-left: auto;
  margin-right: auto;
  @include media(sp) {
    // @include vw(min-width,590);
  }
}

.block {
  position: relative;
  width: 100%;
  min-height: 100vh;
}

.contents {
  width: 600px;
  margin-left: auto;
  margin-right: auto;
  @include media(sp) {
    @include vw(width,590);
  }
}
///////////////////////////////////////
//// テキスト
///////////////////////////////////////

.common-title {
  @include font(32,48,80,48,72,80);
}

.common-title2 {
  @include font(32,48,80,40,40,80);
}

.common-text {
  @include font(12,24,80,24,48,80);
}

.common-divide {
  width: 1px;
  height: 34px;
  background-color: #fff;
  transform: rotate(45deg);
  @include media(sp) {
    @include vw(height,45);
  }
}
///////////////////////////////////////
//// ボタン
///////////////////////////////////////

.button {
  display: block;
  text-align: center;
  border: 1px solid #fff;
  padding: 12px;
  @include font(12,12,80,24,24,80);

  &:hover {
    background-color: #fff;
    color: #000;
  }
}