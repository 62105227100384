@charset "utf-8";
///////////////////////////////////////////
// keyframes.scss
///////////////////////////////////////////

// animation: wakeupgirls 3s alternate ease infinite;

// @keyframes spin
// {
//   0% { transform: rotate(25deg);   }
//   50%   { transform: rotate(-5deg); }
//   100%   { transform: rotate(0deg); }
// }

@keyframes spin
{
  0% { transform: rotate(0deg);}
  50%  { transform: rotate(180deg);}
  100%   { transform: rotate(360deg);}
}

@keyframes jump
{
  0% { 
  	transform: translate3d(0,0,0);
  }
  50% { 
    transform: translate3d(0,20%,0);
  }
  100% { 
    transform: translate3d(0,0,0);
  }
}

@keyframes maskbg
{
  0% { transform: scale(1);   }
  100%   { transform: scale(1.075); }
}

