@charset "utf-8"; ///////////////////////////////////////////
// utilities.scss
///////////////////////////////////////////

.tar {
  text-align: right;
}

.tar-pc {
  text-align: right;
  @include media(sp) {
    text-align: left;
  }
}

.tac {
  text-align: center;
}

.taj {
  text-align: justify;
}

.tac-pc {
  text-align: center;
  @include media(sp) {
    text-align: left;
  }
}

.tar-pc {
  text-align: right;
  @include media(sp) {
    text-align: left;
  }
}

.tac-sp {
  @include media(sp) {
    text-align: center;
  }
}

.mla {
  margin-left: auto;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.center-sp {
  @include media(sp) {
    margin-left: auto;
    margin-right: auto;
  }
}
// flexbox

.flex {
  display: flex;

  &--wp {
    flex-wrap: wrap;
  }

  &--ac {
    align-items: center;
  }

  &--js {
    justify-content: space-between;
  }

  &--jc {
    justify-content: center;
  }

  &--dr {
    flex-direction: row-reverse;
  }
}

.dib {
  display: inline-block;
}

.db {
  display: block;
}

.db-sp {
  @include media(sp) {
    display: block;
  }
}

.posr {
  position: relative;
}

.show_pc {
  display: block;
  @include media(tb) {
    display: none;
  }
  @include media(sp) {
    display: none;
  }
}

.show_pctb {
  display: block;
  @include media(tb) {
    display: block;
  }
  @include media(sp) {
    display: none;
  }
}

.show_tbsp {
  display: none;
  @include media(tb) {
    display: block;
  }
  @include media(sp) {
    display: block;
  }
}

.show_tb {
  display: none;
  @include media(tb) {
    display: block;
  }
  @include media(sp) {
    display: none;
  }
}

.show_sp {
  display: none;
  @include media(tb) {
    display: none;
  }
  @include media(sp) {
    display: block;
  }
}

.show_inline_pctb {
  display: inline;
  @include media(tb) {
    display: inline;
  }
  @include media(sp) {
    display: none;
  }
}

.op a {
  display: inline-block;
  @include trans(opacity, 0.25s, 0s, $easeOutCubic);
}

.op a:hover {
  opacity: 0.6;
  @include media(sp) {
    opacity: 1;
  }
}
// スペーサー
@for $i from 1 through 24 {
  .mb-#{$i * 5} {
    margin-bottom: #{$i * 5px};
  }

  .mt-#{$i * 5} {
    margin-top: #{$i * 5px};
  }

  .ml-#{$i * 5} {
    margin-left: #{$i * 5px};
  }

  .mr-#{$i * 5} {
    margin-right: #{$i * 5px};
  }

  .p-#{$i * 5} {
    padding: #{$i * 5px};
  }

  .pb-#{$i * 5} {
    padding-bottom: #{$i * 5px};
  }

  .pt-#{$i * 5} {
    padding-top: #{$i * 5px};
  }

  .pl-#{$i * 5} {
    padding-left: #{$i * 5px};
  }

  .pr-#{$i * 5} {
    padding-right: #{$i * 5px};
  }
}
@for $i from 0 through 30 {
  .mb-sp-#{$i * 10} {
    @include media(sp) {
      margin-bottom: #{100vw * $i * 10 / $sp-size};
    }
  }

  .mt-sp-#{$i * 10} {
    @include media(sp) {
      margin-top: #{100vw * $i * 10 / $sp-size};
    }
  }

  .ml-sp-#{$i * 10} {
    @include media(sp) {
      margin-left: #{100vw * $i * 10 / $sp-size};
    }
  }

  .mr-sp-#{$i * 10} {
    @include media(sp) {
      margin-right: #{100vw * $i * 10 / $sp-size};
    }
  }

  .p-sp-#{$i * 10} {
    @include media(sp) {
      padding: #{100vw * $i * 10 / $sp-size};
    }
  }

  .pb-sp-#{$i * 10} {
    @include media(sp) {
      padding-bottom: #{100vw * $i * 10 / $sp-size};
    }
  }

  .pt-sp-#{$i * 10} {
    @include media(sp) {
      padding-top: #{100vw * $i * 10 / $sp-size};
    }
  }

  .pl-sp-#{$i * 10} {
    @include media(sp) {
      padding-left: #{100vw * $i * 10 / $sp-size};
    }
  }

  .pr-sp-#{$i * 10} {
    @include media(sp) {
      padding-right: #{100vw * $i * 10 / $sp-size};
    }
  }
}

.mb-1em {
  margin-bottom: 1em;
}

.mb-2em {
  margin-bottom: 2em;
}
// line-height
@for $i from 0 through 20 {
  .lh-#{100 + $i * 10} {
    line-height: #{1 + $i * 0.1};
  }
}
@for $i from 1 through 20 {
  .lh-sp-#{100 + $i * 10} {
    @include media(sp) {
      line-height: #{1 + $i * 0.1};
    }
  }
}
// letter-spacing

.ls-100 {
  letter-spacing: 0.1em;
}

// width
@for $i from 1 through 12 {
  .w-#{$i} {
    width: #{98px * $i - 16};
  }
}
@for $i from 1 through 6 {
  .w-sp-#{$i} {
    @include media(sp) {
      width: #{100vw * (112 * $i - 40) / $sp-size};
    }
  }
}
