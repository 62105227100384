@charset "utf-8"; ///////////////////////////////////////////
// init.scss
///////////////////////////////////////////
// レスポンシブのデバイスサイズ
$bp-pc: 1286px;
$bp-pctb: 751px;
$bp-tb: 751px;
$bp-sp: 750px;
$bp-supernarrow: 600px;
$bp-narrow: 750px; // スマホのデザイン・サイズ
$sp-size: 750; // デフォルトのフォントサイズ
$base-font-size: 16;
$base-line-height: 24; // スペーサー
$spacers: (
  xs: 5,
  ss: 10,
  sm: 15,
  sl: 18,
  s: 20,
  ms: 25,
  m: 30,
  ml: 35,
  l: 40,
  ll: 60,
  3l: 75,
  4l: 100
); // バックグランド
$body-background: #2C2C2C; // 基本テキストカラー
$color-default: #ffffff;
$color-main: #ffffff; // リンクカラー
$color-link: #ffffff; // ホバーリンクカラー
$color-link-hover: #ffffff; // 訪問済みリンクカラー
$color-link-visited: #ffffff; // セレクションカラー
$selection-color: #000;
$color-gold: #d9bc47; // windowsでスクロールバーを常に表示
$color-red: #e5004f;
$color-blue: #00a0c1;
$color-perple: #920783;
$color-yellow: #ffe109;
$color-green: #14ae67;
$color-navy: #00479d;
$color-orange: #f39800;
$color-lime: #cfdb00;
$color-pink: #e60012; // html {
//   overflow-y:scroll;
// }